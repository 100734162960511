<template>
  <div role="main" class="container-fluid">
    <div class="container">
      <dash-board-employee-component v-if="isCurrentRoleEmployee" />
    </div>
    <dash-board-responsible-component v-if="isCurrentRoleResponsible" />
  </div>
</template>

<script>
import {
  DashBoardEmployeeComponent,
  DashBoardResponsibleComponent,
} from "@/pages/dashboard/components";

import { WhoAmIMixin } from "@/components/mixins";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  mixins: [WhoAmIMixin],
  name: "DashBoardPage",
  layout: "MainLayout",
  middleware: ["auth", "notification"],
  components: {
    DashBoardEmployeeComponent,
    DashBoardResponsibleComponent,
  },
  computed: {
    ...mapGetters("auth", ["isCurrentRoleResponsible", "isCurrentRoleEmployee"]),
    ...mapState("auth", ["currentRole"]),
  },
  watch: {
    currentRole() {
      this.loadNotifications();
    },
  },
  methods: {
    ...mapActions("notification", ["loadNotifications"]),
  },
};
</script>
